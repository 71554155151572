
export default {
  props:{
    region:{
      type:String,
      default:'uk',
    },
  },
  data(){
    return{
      recognised: this.region === 'uk' ? 'recognised' : 'recognized'
    }
  },
  computed: {
    backgroundStyles() {
      const imgUrl = this.$img('https://cdn-wp.tefl.org/wp-content/uploads/2024/01/blue-curve-bg.png', { width: '100%',format:'webp',quality:50 })
      return {
        backgroundImage: `url('${imgUrl}')`
      }
    },
  }
}
